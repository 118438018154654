<template>
  <ion-page>
    <AppToolbar>
      <div class="ion-text-wrap">
        {{ $t('Mis jornadas') }}
      </div>

      <template v-slot:end>
        <ion-icon
          :icon="downloadOutline"
          @click="() => {isUserOnline() ? workedDaysReport() : undefined}"
          class="f20 leftSpace"
        ></ion-icon>
      </template>

      <template v-slot:bottom>
        <ion-grid class="ion-grid-padding">
          <TimeToggle v-if="this.gmao.esUsuario" @set="jornadaSet" />
          <ion-row>
            <ion-col size="6">
              <ion-item @click="() => {isUserOnline() ? woDays_from = true : undefined}">
                <ion-input readonly :value="from" style="font-size: 14px" />
                <ion-button fill="clear">
                  <ion-icon :icon="calendarOutline" class="f20" />
                </ion-button>
              </ion-item>
            </ion-col>
            <ion-col size="6">
              <ion-item @click="() => {isUserOnline() ? woDays_to = true : undefined}">
                <ion-input readonly :value="to" style="font-size: 14px" />
                <ion-button fill="clear" id="open-woDays-to">
                  <ion-icon :icon="calendarOutline" class="f20" />
                </ion-button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </template>
    </AppToolbar>

    <ion-progress-bar
      v-if="loading"
      type="indeterminate"
      color="danger"
    ></ion-progress-bar>

    <ion-content class="ion-padding" fullscreen>
      <ion-card
        style="box-shadow: none; border-radius: 15px"
        class="bloque ion-no-margin"
      >
        <ion-card-content class="ion-no-padding">
          <ion-item class="wo-item" lines="none">
            <div class="totalHoras-item">
              <div class="totalHoras-item-label">
                <ion-text>
                  <h2>
                    {{ $t('Total') }}
                  </h2>
                </ion-text>
              </div>
              <div class="totalHoras-item-value">
                <ion-text>
                  {{ formatDuration }}
                </ion-text>
              </div>
            </div>
          </ion-item>
        </ion-card-content>
      </ion-card>

      <template v-if="workedDays?.length">
        <template v-for="(day, i) in workedDays" :key="`day-${i}`">

          <div class="day-item">
            <h5>
              {{ formatDate(day.fecha) }}
            </h5>
            <h5 v-if="day.items?.length > 1" >{{ `${$t('Total')} ${day?.totalDia}` }}</h5>
          </div>

          <ion-card
            style="box-shadow: none; border-radius: 15px"
            class="bloque ion-no-margin"
          >
            <ion-card-content class="ion-no-padding">
              <ion-list lines="inset" style="background-color: transparent;">
                <ion-item
                  class="wo-item"
                  lines="none"
                  v-for='(item, j) in day.items'
                  :key="`hora-${j}`"
                >
                  <div class="hora-item" @click='resolveJornadaItem(item)'>
                    <div class="hora-item-tecnico">
                      <h2>
                        <template v-if='item?.direccion?.length'>
                          <ion-text>
                            {{ item.direccion }}
                          </ion-text>
                        </template>
                        <template v-else>
                          <ion-text style="color: #21212161">
                            {{ $t('Sin dirección') }}
                          </ion-text>
                        </template>
                      </h2>

                      <h3 class="ion-text-wrap wrap-text hora-tipo"  style="color:#666666">
                        {{ `${$t('Total')} ${item.duracion}` }}
                        <template v-if="item.edited">
                          <i>{{ `(${$t('Editado')})` }}</i>
                        </template>
                      </h3>
                    </div>
                    <div class="hora-item-hora">
                      <p class="wrap-text" style="color:#666666">
                        <!-- <b>{{$t('Desde')}}</b> <br /> -->
                        {{ item.inicio }}
                      </p>
                      <p class="wrap-text" style="color:#666666">
                        <!-- <b>{{$t('Hasta')}}</b> <br /> -->
                        <template v-if="!item.fin">
                          —
                        </template>
                        <template v-else>
                          {{ item.fin }}
                        </template>
                      </p>
                    </div>
                  </div>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </template>
      </template>

      <template v-else>
        <ion-item lines='none'>
          <ion-label>
            <h3>{{ $t('no-hay-jornadas-para-mostrar') }}</h3>
            <p>{{ $t('comprueba-los-filtros-y-vuelve-a-intentarlo-0') }}</p>
          </ion-label>
        </ion-item>
      </template>
    </ion-content>

    <ion-modal
      ref="modal"
      :initial-breakpoint="0.75"
      :is-open="isEditJornadaVisible"
      :breakpoints="[0, 0.25, 0.5, 0.75]"
      @didDismiss="isEditJornadaVisible = false"
    >
      <ion-header>
        <ion-toolbar class="editJornadaToolbar">
          <ion-buttons slot="start">
            <ion-button @click="isEditJornadaVisible = false">
              <ion-icon :icon="closeOutline" class="f20" />
            </ion-button>
          </ion-buttons>

          <ion-title>{{ $t('Editar jornada') }}</ion-title>

          <ion-buttons slot="end">
            <ion-button color='primary' style='text-transform: capitalize;' @click="() => setEditJornada()">{{ $t('Aceptar') }}</ion-button>
          </ion-buttons>

        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" style="height:calc(100% - 80px); --background: #fff;">
        <ion-list style="background-color: #fff !important;">
          <ion-item class="ion-no-padding" disabled>
            <ion-label style='text-transform: capitalize;' position="stacked">{{ $t("Total horas") }}</ion-label>
            <ion-input
              class="ion-text-left" :value="currentEditableItem.duracion"></ion-input>
          </ion-item>

          <ion-item class="ion-no-padding" disabled>
            <ion-label style='text-transform: capitalize;' position="stacked">{{ $t("Dirección") }}</ion-label>
            <ion-input
              class="ion-text-left" :value="currentEditableItem.direccion || $t('Sin dirección')"></ion-input>
          </ion-item>

          <template v-if='currentEditableItem.edited?.length'>
            <ion-item class="ion-no-padding" disabled>
              <ion-label position="stacked">{{ $t("edited_by") }}</ion-label>
              <ion-input class="ion-text-left" :value="currentEditableItem.edited"></ion-input>
            </ion-item>
          </template>

          <ion-item class="ion-no-padding" style="--background:#fff;">
            <ion-input
              :label='`${$t("pause_time")} (min)`'
              class="ion-text-right"
              type='number'
              v-model="currentEditableItem.break_time"
            ></ion-input>
          </ion-item>

          <ion-item style="--background:#fff;" class="ion-no-padding">
            <ion-label>{{ $t('Empieza') }}</ion-label>
            <div class="time-edit-item">
              <ion-datetime-button datetime="imputacionDatetime" />
              <input class="ion-text-right" @change="($event) => setTimeEdit('inicio', $event)" v-model="currentEditableItem.inicio" type="time" />
            </div>

            <ion-modal class="date-modal" :keep-contents-mounted="true">
              <ion-datetime :max="moment().format('YYYY-MM-DD')" v-model="currentEditableItem.desde" style="color: black" id="imputacionDatetime"
                presentation="date"></ion-datetime>
            </ion-modal>
          </ion-item>

          <ion-item style="--background:#fff;" class="ion-no-padding">
            <ion-label>{{ $t('Termina') }}</ion-label>

            <div class="time-edit-item">
              <ion-datetime-button datetime="imputacionDatetime2"/>
              <input class="ion-text-right" @change="($event) => setTimeEdit('fin', $event)" v-model="currentEditableItem.fin" type="time"/>
            </div>

            <ion-modal class="date-modal" :keep-contents-mounted="true">
              <ion-datetime :max="moment().format('YYYY-MM-DD')" v-model="currentEditableItem.hasta" style="color: black" id="imputacionDatetime2"
                presentation="date"></ion-datetime>
            </ion-modal>
          </ion-item>

        </ion-list>
      </ion-content>
    </ion-modal>

    <ion-modal class="date-modal" :is-open="woDays_from" @didDismiss="woDays_from = false">
      <ion-content force-overscroll="false">
        <ion-datetime presentation="date" @ionChange="fechaFrom" firstDayOfWeek=1 />
      </ion-content>
    </ion-modal>

    <ion-modal class="date-modal" :is-open="woDays_to" @didDismiss="woDays_to = false">
      <ion-content force-overscroll="false">
        <ion-datetime presentation="date" @ionChange="fechaTo" firstDayOfWeek=1 />
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonProgressBar,
  IonIcon,
  toastController,
  IonDatetime,
  IonInput,
  IonItem,
  IonModal,
  IonButton,
} from '@ionic/vue';
import {
  calendar,
  closeOutline,
  calendarOutline,
  downloadOutline,
  chevronDownOutline,
} from 'ionicons/icons';

import moment from 'moment';


// SQLite
import { SQLiteDBConnection } from 'vue-sqlite-hook/dist';

import { useOfflineStore } from '@/stores/offline';

import AppToolbar from '@/components/AppToolbar.vue';
import { useGmaoStore } from '@/stores/gmao';
import TimeToggle from '@/components/TimeToggle.vue';

import { ref, getCurrentInstance } from 'vue';

export default {
  name: 'workedDaysPage',

  components: {
    AppToolbar,
    IonContent,
    IonPage,
    IonProgressBar,
    IonIcon,
    IonDatetime,
    IonItem,
    IonInput,
    IonModal,
    IonButton,
    TimeToggle,
  },

  created() {
    const weekStart = this.$moment(this.$moment()).startOf('iweek').isoWeekday(1);
    this.from = weekStart.format('L');
    // this.from = this.$moment().subtract(7, 'days').format('DD-MM-YYYY');
    this.to = this.$moment(weekStart).add(6, 'days').format('L');
    this.applyFilters(true);
  },

  computed: {
    totalHoras() {
      if (!this.workedDays?.length) return 0;

      return this.workedDays.reduce((a, b) => {
        return a + +b.totalHoras;
      } , 0);
    },
    formatDuration() {
      const duracion = moment.duration(this.totalHoras, 'seconds');
      // const dias = Math.floor(duracion.asDays());
      const horas = Math.floor(duracion.asHours());
      const minutos = duracion.minutes();

      return `${horas && ` ${horas}h` || ''}${minutos && ` ${minutos}min`}`;
    },
  },

  setup() {
    const gmao = useGmaoStore();
    const offline = useOfflineStore();

    const app = getCurrentInstance();

    return {
      // ICONs
      calendar,
      calendarOutline,
      downloadOutline,
      chevronDownOutline,
      closeOutline,

      // NOTE: STOREs
      gmao,
      offline,
      app,

      // NOTE: VARs
      workedDays: ref([]),
      loading: ref(false),
      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),
      from: ref(''),
      to: ref(''),
      totalHours: ref(0),

      isEditJornadaVisible: ref(false),
      currentEditableItem: ref(null),

      woDays_from: ref(false),
      woDays_to: ref(false),

      // NOTE: COMPONENTs
      moment,
    };
  },

  methods: {

    isUserOnline() {
      if (this.offline.status) {
        this.$openToastObject(
          this.$t('offline-generic-action-not-available-msg'),
          this.$t('offline-generic-action-not-available-msg-guard'),
          'danger'
        );
        return false;
      }

      return true;
    },

    setTimeEdit (key, event) {
      if (key?.length) {
        this.currentEditableItem[key] = event?.target.value;
      }
    },


    fechaFrom(ev) {
      this.from = this.$moment(ev.detail.value).format('L');
      this.applyFilters(true);
    },
    fechaTo(ev) {
      this.to = this.$moment(ev.detail.value).format('L');
      this.applyFilters(true);
    },
    resolveJornadaItem(item) {
      if (this.$hasPermissions('jornadas', 'editar')) {
        if (+item.break_time) item.break_time = item.break_time / 60; //NOTE: Seconds to minutes
        else item.break_time = 0;

        this.currentEditableItem = structuredClone(item);

        this.currentEditableItem.inicio = this.currentEditableItem.inicio.split(' h')[0];
        this.currentEditableItem.fin = this.currentEditableItem.fin.split(' h')[0];

        this.isEditJornadaVisible = true;
      }
    },


    async setEditJornada() {
      
      this.currentEditableItem.desde = this.moment(this.currentEditableItem.desde).format('YYYY-MM-DD');
      this.currentEditableItem.hasta = this.moment(this.currentEditableItem.hasta).format('YYYY-MM-DD');

      const local_durationInMin = this.moment.duration(
        this.moment(`${this.currentEditableItem.hasta} ${this.currentEditableItem.fin}`, 'YYYY-MM-DD HH:mm:ss')
            .diff(this.moment(`${this.currentEditableItem.desde} ${this.currentEditableItem.inicio}`, 'YYYY-MM-DD HH:mm:ss'))
      )?.asMinutes();

      if (local_durationInMin < this.currentEditableItem.break_time) {
        this.$openToastObject(
          this.$t('Ha ocurrido un error'),
          this.$t('duration_time_exceeded')
        );
        return;
      }

      const validateTime = await this.$isTimeValid({
        inicio: this.currentEditableItem.inicio,
        fin: this.currentEditableItem.fin
      },
      {
        desde: this.currentEditableItem.desde,
        hasta: this.currentEditableItem.hasta
      }
      );
      
      if (!validateTime) { return; }

      const formdata = this.$objectToFormData(this.currentEditableItem);

      this.$axios
        .post(`/v2/users/actions.php?call=setEditJornada&token=${this.gmao.user.token}&v2=1`, formdata)
        .then(() => {
          this.currentEditableItem = {};
          this.getWorkedDays();
          this.isEditJornadaVisible = false;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              const ret = await sqlite.checkConnectionsConsistency();
              const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              let db = new SQLiteDBConnection();
              if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              } else {
                db = await sqlite.createConnection(
                  'gmaoTecnicos',
                  false,
                  'no-encryption',
                  1,
                  false
                );
              }

              await db.open();
              
              const jornada = this.offline.workedDays?.map((i) => i.items).flat()
                .find((j) => j.id == this.currentEditableItem.id)?.jornada || {};

              const syncObject = {
                callFunction: 'setEditJornada',
                data: {...this.currentEditableItem}
              };
                
              
              const query = `
                INSERT OR REPLACE INTO jornada_horas
                (
                  id,
                  syncObject,
                  last_modified,
                  id_tecnico,
                  id_direccion,
                  edited,
                  inicio,
                  fin,
                  fecha,
                  fecha_fin,
                  desde,
                  hasta,
                  desde_tz,
                  hasta_tz,
                  tiempo_comida,
                  duracion,
                  geo_inicio,
                  geo_fin,
                  deleted_at,
                  created_at,
                  updated_at
                )
                VALUES (
                  ${jornada?.id || ''},
                  '${JSON.stringify(syncObject)}',
                  '${this.$moment().format('YYYY-MM-DD H:mm:ss')}',
                  ${jornada?.id_tecnico || 'null'},
                  ${jornada?.id_direccion || 'null'},
                  ${jornada?.edited || 1},
                  '${jornada?.inicio || 'null'}',
                  '${jornada?.fin || 'null'}',
                  '${jornada?.fecha || 'null'}',
                  '${jornada?.fecha_fin || 'null'}',
                  '${jornada?.desde || 'null'}',
                  '${jornada?.hasta || 'null'}',
                  '${jornada?.desde_tz || 'null'}',
                  '${jornada?.hasta_tz || 'null'}',
                  ${jornada?.tiempos_comida || 'null'},
                  ${jornada?.duracion || 'null'},
                  '${JSON.stringify(jornada?.geo_inicio) || {}}',
                  '${JSON.stringify(jornada?.geo_fin) || {}}',
                  '${jornada?.deleted_at || 'null'}',
                  '${jornada?.created_at || 'null'}',
                  '${jornada?.updated_at || 'null'}'
                )
              `;
              
              const setEditWorkedDay = new Promise((resolve) => {
                resolve(
                  this.$SQLiteQuery(
                    'setEditWorkedDay_workeddays',
                    'jornada_horas',
                    query,
                    e.config,
                    db
                  )
                );
              });
              setEditWorkedDay.then(() => {
                
                setTimeout(() => {
                  const jornadaIndex = this.offline.workedDays.findIndex(jornada =>
                    jornada.items.some(i => i.id === this.currentEditableItem.id)
                  );
                  
                  if (jornadaIndex !== -1) {
                    const itemIndex = this.offline.workedDays[jornadaIndex].items.findIndex(i => i.id === this.currentEditableItem.id);

                    if (itemIndex !== -1) {
                      const originalItem = this.offline.workedDays[jornadaIndex].items[itemIndex];

                      const duration = this.calculateDuration(
                        this.currentEditableItem.inicio,
                        this.currentEditableItem.fin,
                        this.currentEditableItem.break_time
                      );
                      
                      originalItem.direccion = this.currentEditableItem.direccion;
                      originalItem.break_time = this.currentEditableItem.break_time * 60;
                      originalItem.desde = this.currentEditableItem.desde;
                      originalItem.hasta = this.currentEditableItem.hasta;
                      originalItem.duracion = this.formatDuration2(duration);
                      originalItem.inicio = `${this.currentEditableItem.inicio} h`;
                      originalItem.fin = `${this.currentEditableItem.fin} h`;
                      originalItem.edited = this.gmao.user?.nombre_completo;
                      originalItem.total_duration = duration;

                      const updatedTotalDuration = this.offline.workedDays[jornadaIndex].items.reduce(
                        (total, item) => total + item.total_duration, 0
                      );
                      this.offline.workedDays[jornadaIndex].totalHoras = updatedTotalDuration;

                      const totalHorasFormatted = this.formatDuration2(updatedTotalDuration);
                      this.offline.workedDays[jornadaIndex].totalDia = totalHorasFormatted;

                      this.offline.$patch({
                        workedDays: [...this.offline.workedDays]
                      });

                      this.isEditJornadaVisible = false;
                    }
                  }


                  this.currentEditableItem = {};
                  this.getWorkedDays();
                }, 500);
              });
            } catch (err) {
              console.error(err);
            }
          }
        });
    },
    calculateDuration(inicio, fin, break_time) {
      const [inicioHoras, inicioMinutos] = inicio.split(":").map(Number);
      const [finHoras, finMinutos] = fin.split(":").map(Number);
      
      let duracionMinutos = (finHoras * 60 + finMinutos) - (inicioHoras * 60 + inicioMinutos) - break_time;
      if (duracionMinutos < 0) duracionMinutos = 0; // Evitar tiempos negativos
      
      return duracionMinutos * 60; // Retorna duración en segundos
    },
    formatDuration2(durationInSeconds) {
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.floor((durationInSeconds % 3600) / 60);
      return `${hours}h ${minutes}min`;
    },
    getWorkedDays() {
      this.loading = true;
      return this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'getWorkedDays',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
            start: this.$moment(this.from, true).unix(),
            end: this.$moment(this.to, true).unix(),
            page: this.current_page,
          },
        })
        .then(({ data }) => {
          this.workedDays = data;

          // const jornadas = this.mergeEntries(data.data);
          // this.workedDays.push(jornadas);

          // const totalDuration = data.data.map((v) => +v.duracion).reduce((a, b) => a + b, 0);
          // const duration = this.moment.duration(totalDuration, 'seconds');

          // this.totalHours = `${parseInt(duration.asHours())},${parseInt(duration.asMinutes()) % 60}`

          // this.current_page = data.current_page;
          // this.last_page = data.last_page;
          // this.total = data.total;
        }).catch(async (e) => {
          if (e.code == 'ERR_NETWORK' && this.offline.status) {
            try {
              // const sqlite = this.app?.appContext.config.globalProperties.$sqlite;

              // const ret = await sqlite.checkConnectionsConsistency();
              // const isConn = (await sqlite.isConnection('gmaoTecnicos', false)).result;

              // let db = new SQLiteDBConnection();
              // if (ret.result && isConn) {
              //   db = await sqlite.retrieveConnection('gmaoTecnicos', false);
              // } else {
              //   db = await sqlite.createConnection(
              //     'gmaoTecnicos',
              //     false,
              //     'no-encryption',
              //     1,
              //     false
              //   );
              // }

              // await db.open();

              // NOTE: Mantenemos registros en localStorage para avanzar rapido
              // XXX: FIXME: OFFLINE hay que cambiarlo
              this.workedDays = this.offline.workedDays;
              
              // const query = `
              //   SELECT *
              //   FROM jornada_horas
              // `;

              // const getWorkedDays = new Promise((resolve) => {
              //   resolve(
              //     this.$SQLiteQuery(
              //       'getWorkedDays_workeddays',
              //       'jornada_horas',
              //       query,
              //       e.config,
              //       db
              //     )
              //   );
              // });
              // getWorkedDays.then(() => {
              //   setTimeout(() => {
              //     this.workedDays = this.offline.workedDays;
              //   }, 500);
              // });
            } catch (err) {
              this.$Sentry.captureException(err);
            }
          }
        }).finally(() => {
          this.loading = false;
        });
    },

    mergeEntries(data) {
      const mergedData = data.reduce((acc, curr) => {
        if (!acc[curr.fecha]) {
          acc[curr.fecha] = { ...curr };
        } else {
          acc[curr.fecha].duracion += curr.duracion;
          acc[curr.fecha].inicio = this.moment.min(this.moment(acc[curr.fecha]?.inicio, 'HH:mm:ss'), this.moment(curr?.inicio, 'HH:mm:ss')).format('HH:mm:ss');
          acc[curr.fecha].fin = this.moment.max(this.moment(acc[curr.fecha]?.fin, 'HH:mm:ss'), this.moment(curr?.fin, 'HH:mm:ss')).format('HH:mm:ss');
          acc[curr.fecha].jornadas = data.filter((d) => d.fecha == curr.fecha);
        }

        return acc;
      }, {});

      return Object.values(mergedData);
    },

    onLoad(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters(false).then(() => {
          event.target.complete();
        });
      } else {
        event.target.disabled = true;
      }
    },

    applyFilters(reset = true) {
      if (reset) {
        this.workedDays = [];
        this.current_page = 1;
      }

      return this.getWorkedDays(this.current_page);
    },

    workedDaysReport() {
      const host = this.gmao?.workspace?.api?.split("/api")[0];
      
      window.open(
        `${host}/admin/informes/resumenjornadamap.php?token=${this.gmao.user?.token}&from=${this.$moment(this.from, true).unix()}&to=${this.$moment(this.to, true).unix()}`
      );
    },

    async sendPDFEmail() {

      const toast = await toastController.create({
        header: this.$t('solicitud-enviada'),
        message: this.$t('hemos-registrado-tu-solicitud-te-enviamos-un-email'),
        position: 'bottom',
        color: 'tertiary',
        duration: '4000',
      });

      this.$axios
        .get('/v2/users/actions.php', {
          params: {
            call: 'sendPDFWorkedDays',
            u: this.gmao.user.id,
            token: this.gmao.user.token,
            v2: 1,
            start: this.from,
            end: this.to,
          },
        })
        .finally(() => {
          return toast.present();
        });
    },

    jornadaSet() {
      this.applyFilters(true);
    },

    formatDate(date) {
      const today = this.$moment();
      const yesterday = this.$moment().subtract(1, 'days');
      const momentDate = this.$moment(date);

      if (momentDate.isSame(today, 'day')) return this.$t('Hoy');
      else if (momentDate.isSame(yesterday, 'day')) return this.$t('Ayer');
      else return momentDate.format('D [de] MMMM');
    },
  },
};
</script>

<style lang="scss" scoped>
ion-content {
  --ion-background-color: #f6f6f6;
}
.title {
  text-transform: capitalize;
  --ion-background-color: transparent;
  margin-bottom: -15px !important;
}

h5 {
  font-size: 10pt !important;
  color: #767676;
}
.bloque {
  margin-bottom: 1em !important;
}

ion-card {
  --background: white !important;
}

.wo-item {
  /*border: 1px solid #dadada;*/
  margin-bottom: 0;
  height: 100%;
  --background: white !important;

  // & ion-text { margin-bottom: 20px; }
  & p {
    font-size: 12px;
  }
}

.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}
.workorder-card {
  min-height: 100px;
  background: #faf7f7;
  // border: 1px solid #F7F7F7;
  border-radius: 0, 8px, 8px, 0;

  // border-left: 15px solid #00ACAC;
  &::part(native) {
    background-color: #fff;
    min-height: 100px;
  }
}

// NOTE: totalHoras styles
.totalHoras-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // REVIEW: Revisar que esto sea dinamico
  padding-top: 24px;
  padding-bottom: 24px;
}

.day-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.hora-item {
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  padding-top: 12px;
  padding-bottom: 12px;

  &-hora {
    text-align: right;
  }

  &-tecnico {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.time-edit-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  column-gap: 12px;
  row-gap: 8px;

  input[type='time'] {
    border-width: 0px;
    border-radius: 8px;
    padding: 4px;
    background-color: #edeef0;
  }
}

.date-modal {
  background-color: rgba(0, 0, 0, 0.4);
  --width: 290px;
  --height: 382px;
  --border-radius: 8px;
}

.date-modal ion-datetime {
  height: 382px;
  width: 100%;
}
</style>
